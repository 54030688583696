import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import useBodyClass from '../../hooks/body-class';
import './ListPage.css';
import { LoggedContext } from '../../contexts/LoggedContext';
import LayoutLogged from '../LayoutLogged';
import './FormPage.css';
import OrdemDeProducaoService from '../../services/OrdemDeProducaoService';
import modalManager from '../../helpers/modalManager';

const paradas = {
  'parada_programada': {
    'limpeza_de_maquina': 'Limpeza de Máquinas',
    'manutencao_preventiva': 'Manutenção Preventiva',
    'raspar_chapa': 'Raspar Chapas',
    'refeicao': 'Refeições, Almoço/Jantar',
    'reuniao_treinamento': 'Reuniões, Treinamentos',
    'troca_de_cor': 'Troca de Cores',
    'intervalos_de_operacao': 'Intervalos de Operação'
  },
  'parada_nao_programada': {
    'troca_de_quadro': 'Troca de Quandros',
    'ajuste_de_cor': 'Ajustes de Cores',
    'conserto_de_quadro': 'Conserto de Quadros',
    'falta_de_pessoal': 'Falta de Pessoal',
    'falta_de_ferramenta': 'Falta de Ferramentas',
    'falta_de_materia_prima': 'Falta de Matéria Prima',
    'manutencao_corretiva': 'Manutenção Corretiva',
    'quebra_de_linha': 'Quebra de Linha',
    'limpeza_de_cisco': 'Limpeza de Cisco',
    'sem_motivo': 'Sem Motivo'
  }
}

export default function FormPage() {

  const context = useContext(LoggedContext);
  const params = useParams();
  const ordemId = parseInt(params.ordemId);
  const [podeProduzir, setPodeProduzir] = useState(false);
  const [ordem, setOrdem] = useState();
  const [disponibilidadeAberta, setDisponibilidadeAberta] = useState();
  const [eventoAberto, setEventoAberto] = useState();
  const [logs, setLogs] = useState([]);
  const cronometroInterval = useRef();

  useBodyClass("page-opform");

  useEffect(() => {
    return function cleanupFormPage() {
      clearInterval(cronometroInterval.current);
      context.appEvents.unsubscribe(onAppEvent);
    }
  }, []);

  useEffect(() => {
    init();
  }, [ordemId]);

  useEffect(() => {
    exibirCronometro();
  }, [eventoAberto]);

  async function init() {
    setPodeProduzir(await OrdemDeProducaoService.podeProduzir(context.maquina.id, ordemId));
    setOrdem(await OrdemDeProducaoService.getOrdemById(ordemId));
    setDisponibilidadeAberta(await OrdemDeProducaoService.getDisponibilidadeAberta(context.maquina.id));
    await carregaLog();
    let eventoAberto = await OrdemDeProducaoService.getEventoAberto(ordemId);
    if (eventoAberto) {
      setEventoAberto(eventoAberto);
    }
    context.appEvents.subscribe(onAppEvent);
  }

  async function iniciarEvento(tipo) {
    modalManager.mount(
      'modalOperador',
      <ModalEscolherOperador
        operadores={context.operadores}
        onConfirm={async (operador) => {
          await OrdemDeProducaoService.iniciaEvento(
            ordemId, 
            context.maquina.id, 
            disponibilidadeAberta.uuid, 
            tipo, 
            operador
          );
          setEventoAberto(await OrdemDeProducaoService.getEventoAberto(ordemId));
          modalManager.hide();
        }}
      />
    );
    modalManager.show('modalOperador');
  }

  async function terminarEvento() {
    if (eventoAberto.tipo == 'setup'
      || eventoAberto.tipo == 'producao') {
      await OrdemDeProducaoService.terminaEvento(eventoAberto._id);
      setEventoAberto(null);
      carregaLog();
    } else {
      modalManager.mount(
        'modalEscolherMotivo',
        <ModalEscolherMotivo
          eventoAberto={eventoAberto}
          onConfirm={async (motivo) => {
            await OrdemDeProducaoService.terminaEvento(eventoAberto._id, motivo);
            setEventoAberto(null);
            carregaLog();
            modalManager.hide();
          }}
        />
      );
      modalManager.show('modalEscolherMotivo');
    }
  }

  async function exibirCronometro() {
    if (!eventoAberto) {
      return;
    }
    const inicio = new Date(eventoAberto.inicio);
    const divCronometro = document.getElementById('cronometro');
    cronometroInterval.current = setInterval(() => {
      const agora = new Date();
      const diff = agora.getTime() - inicio.getTime();
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      divCronometro.innerText = hours + ":" + ("0" + minutes).substr(-2) + ":" + ("0" + seconds).substr(-2);
    }, 1000);
  }

  async function carregaLog() {
    let logs = await OrdemDeProducaoService.logs(ordemId);
    setLogs(logs);
  }

  function legendaDoEvento(tipo) {
    switch (tipo) {
      case 'setup':
        return 'Setup';
      case 'producao':
        return 'Produção';
      case 'parada_programada':
        return 'Parada Programada';
      case 'parada_nao_programada':
        return 'Parada Não Programada';
    }
  }

  async function iniciaDisponibilidadeDeMaquina(evt) {
    evt.preventDefault();
    modalManager.mount(
      'modalOperador',
      <ModalEscolherOperador
        operadores={context.operadores}
        onConfirm={async (operador) => {
          let disponibilidade = await OrdemDeProducaoService.iniciaDisponibilidadeDeMaquina(ordemId, context.maquina.id, operador);
          setDisponibilidadeAberta(disponibilidade);
          carregaLog();
          modalManager.hide();
        }}
      />
    );
    modalManager.show('modalOperador');
  }

  async function terminaDisponibilidadeDeMaquina(evt) {
    evt.preventDefault();
    modalManager.mount(
      'modalOperador',
      <ModalEscolherOperador
        operadores={context.operadores}
        onConfirm={async (operador) => {
          await OrdemDeProducaoService.terminaDisponibilidadeDeMaquina(disponibilidadeAberta, operador);
          setDisponibilidadeAberta(null);
          carregaLog();
          modalManager.hide();
        }}
      />
    );
    modalManager.show('modalOperador');
  }

  function onAppEvent(evtName, data) {
    if (evtName === 'sincronizacao-concluida') {
      carregaLog();
    }
  }

  return (
    <LayoutLogged>
      <div className="form">
        <div className="ordemproducao">
          {ordem && <>
            <div className="row mb-2">
              <div className="col-4 label">Cliente:</div>
              <div className="col-8 value">{ordem.cliente.nome}</div>
            </div>
            <div className="row mb-2">
              <div className="col-4 label">Referência:</div>
              <div className="col-8 value">{ordem.referencia}</div>
            </div>
            <div className="row mb-2">
              <div className="col-4 label">OF Cliente:</div>
              <div className="col-8 value">{ordem.ofCliente}</div>
            </div>
            <div className="row mb-2">
              <div className="col-4 label">Marca:</div>
              <div className="col-8 value">{ordem.marca}</div>
            </div>
            <div className="row mb-2">
              <div className="col-4 label">Quantidade:</div>
              <div className="col-8 value">{ordem.quantidade}</div>
            </div>
          </>
          }
        </div>
        <div className={`evento ${eventoAberto?.tipo}`}>
          {!eventoAberto &&
            <>Sem evento aberto.</>
          }
          {eventoAberto && <>
            <div className="tipo">
              {legendaDoEvento(eventoAberto?.tipo)}
            </div>
            <div id="cronometro"></div>
            <div className="info">
              {(new Date(eventoAberto.inicio)).toLocaleString()}<br />
              {eventoAberto.operador.nome}
            </div>
            <div className="concluir">
              <button type="button" onClick={e => terminarEvento()}>Concluir Evento</button>
            </div>
          </>
          }
        </div>
        <div className="log">
          <table>
            <thead>
              <tr>
                <th scope="col">Evento</th>
                <th scope="col">Início</th>
                <th scope="col">Fim</th>
                <th scope="col">Operador</th>
                <th scope="col">Sincronização</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log, index) => (
                <tr key={index}>
                  <td>
                    {log.tipo ? legendaDoEvento(log.tipo) : 'Disponibilidade'}
                    {log.motivo && <>
                      <br />{paradas[log.tipo][log.motivo]}
                    </>}
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>{(new Date(log.inicio)).toLocaleString()}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>{log.fim ? (new Date(log.fim)).toLocaleString() : ''}</td>
                  <td>{log.tipo ? log.operador.nome : log.operadorInicio.nome + ' / ' + log.operadorFim?.nome}</td>
                  <td>{log._sync == 'true' ? <span className='sync-ok'>Ok</span> : ''}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="menu">
          {!podeProduzir && disponibilidadeAberta &&
            <div className="msg-naopodeproduzir-container">
              Esta Ordem de Produção não pode ser produzida porque a OP com ID {disponibilidadeAberta.ordemProducaoId} está em andamento:
              <div className="mt-3">
                <Link className="btn btn-secondary btn-lg" to={`/op-estamparia/${disponibilidadeAberta.ordemProducaoId}`}>
                  VER ORDEM DE PRODUÇÃO
                </Link>
              </div>
            </div>
          }
          {podeProduzir && !disponibilidadeAberta && 
            <ul>
              <li>
                <button
                  type="button"
                  className="alocar"
                  onClick={iniciaDisponibilidadeDeMaquina}>
                  Iniciar Disponibilidade
                </button>
              </li>
            </ul>
          }
          {podeProduzir && disponibilidadeAberta && 
            <ul>
              <li>
                <button
                  type="button"
                  className="addsetup"
                  disabled={!!eventoAberto}
                  onClick={e => iniciarEvento('setup')}>
                  Setup
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="addproducao"
                  disabled={!!eventoAberto}
                  onClick={e => iniciarEvento('producao')}>
                  Produção
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="addparada"
                  disabled={!!eventoAberto}
                  onClick={e => iniciarEvento('parada_nao_programada')}>
                  Parada Não Programada
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="addparada"
                  disabled={!!eventoAberto}
                  onClick={e => iniciarEvento('parada_programada')}>
                  Parada Programada
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="desalocar"
                  disabled={!!eventoAberto}
                  onClick={terminaDisponibilidadeDeMaquina}>
                  Encerrar Disponibilidade
                </button>
              </li>
            </ul>
          }
        </div>
      </div>
    </LayoutLogged>
  );
}

function ModalEscolherOperador({ operadores, onConfirm }) {

  function confirmaOperador(operador) {
    onConfirm(operador);
  }

  return (
    <div className="modal" id="modalOperador" tabIndex="-1">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Escolha um Operador</h5>
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close">Cancelar</button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-8">
                <ul className="operadores">
                  {(operadores || []).map((operador, index) => (
                    <li key={index}>
                      <button className="btn btn-outline-dark" onClick={e => confirmaOperador(operador)}>
                        {operador.nome}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-4">
                Recentes
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ModalEscolherMotivo({ onConfirm, eventoAberto }) {

  function confirmaMotivo(motivo) {
    onConfirm(motivo);
  }

  return (
    <div className="modal" id="modalEscolherMotivo" tabIndex="-1">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Escolha o Motivo da Parada</h5>
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close">Cancelar</button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col">
                <ul className="motivos">
                  {(() => {
                    let ret = [];
                    let motivos = paradas[eventoAberto.tipo];
                    for(let motivo in motivos){
                      ret.push(
                        <li key={motivo}>
                          <button className="btn btn-outline-dark" onClick={e => confirmaMotivo(motivo)}>
                            {paradas[eventoAberto.tipo][motivo]}
                          </button>
                        </li>
                      );
                    }
                    return ret;
                  })()}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}