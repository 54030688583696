import Funcionario from './Funcionario';

const DisponibilidadeDeMaquina = {
    _sync: false,
    _status: "aberta",
    id: 0,
    uuid: null,
    ordemProducaoId: 0,
    maquinaId: 0,
    inicio: "",
    fim: "",
    operadorInicio: JSON.parse(JSON.stringify(Funcionario)),
    operadorFim: JSON.parse(JSON.stringify(Funcionario))
}

export default DisponibilidadeDeMaquina;