import parseJwt from './jwt';
import MaquinaModel from '../models/Maquina';
import api from './api';

const TOKEN_NAME_LEGADO = 'login-terminal-token';
const TOKEN_NAME_BASE = "token-avance-maquina";
const MAQUINA_ONLINE = "avance-maquina-online";

const auth = {
    getToken: maquinaId => {
        return localStorage.getItem(TOKEN_NAME_BASE + maquinaId);
    },
    getTokenOnline: () => {
        let tokenLegado = localStorage.getItem(TOKEN_NAME_LEGADO);
        if (tokenLegado) {
            auth.saveToken(tokenLegado);
            localStorage.removeItem(TOKEN_NAME_LEGADO);
        }
        let maquinaId = localStorage.getItem(MAQUINA_ONLINE);
        return auth.getToken(maquinaId);
    },
    setOnline: maquinaId => {
        localStorage.setItem(MAQUINA_ONLINE, maquinaId);
    },
    unsetOnline: () => {
        localStorage.setItem(MAQUINA_ONLINE, "");
    },
    isAuthenticated: () => {
        return auth.getTokenOnline() !== null;
    },
    saveToken: token => {
        let tokenDecoded = parseJwt(token);
        localStorage.setItem(TOKEN_NAME_BASE + tokenDecoded.id, token);
    },
    login: async (dados) => {
        let url = '/maquina/login';
        let response = await api.post(url, dados);
        if (response.data.status == 'ok') {
            auth.saveToken(response.data.token);
            auth.setOnline(dados.maquinaId);
        }
        return response;
    },
    logout: maquinaId => {
        localStorage.removeItem(TOKEN_NAME_BASE + maquinaId);
    },
    refreshToken: async () => {
        // let usuario = auth.getUsuario();
        // if (usuario.role == 'estamparia') {
        //     let resp = await api.post('/estamparia/refresh-token');
        //     auth.saveToken(resp.data);
        // }
    },
    getUsuario: token => {
        if ( ! token) {
            token = auth.getTokenOnline();
        }
        if ( ! token) {
            return null
        }
        let tokenDecoded = parseJwt(token);
        let usuario = JSON.parse(JSON.stringify(MaquinaModel));
        usuario.id = tokenDecoded.id;
        usuario.role = tokenDecoded.role;
        usuario.nome = tokenDecoded.nome;
        usuario.estamparia = tokenDecoded.estamparia;
        return usuario;
    },
    listMaquinasLogadas: () => {
        let keys = Object.keys(localStorage);
        let logadas = [];
        for (const key of keys) {
            if (/token-avance-maquina[0-9]*/.test(key)) {
                let maquinaId = key.match(/\d+/g)[0];
                let maquina = auth.getUsuario(auth.getToken(maquinaId));
                logadas.push(maquina);
            }
        }
        logadas = logadas.sort((a, b) => {
            if (a.nome > b.nome) return 1;
            if (a.nome < b.nome) return -1;
            return 0;
        });
        return logadas;
    }
}

export default auth;