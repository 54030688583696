import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import { LoggedContext } from '../contexts/LoggedContext';
import { ToastContainer } from 'react-toastify';
import { Slide } from 'react-toastify';
import { Link } from 'react-router-dom';
import ModalLogout from '../components/OPEstamparia/ModalLogout';
import modalManager from '../helpers/modalManager';
import useBodyClass from '../hooks/body-class';
import OrdemDeProducaoService from '../services/OrdemDeProducaoService';
import 'react-toastify/dist/ReactToastify.min.css';
import './LayoutLogged.css';

export default function LayoutLogged(props) {

  const context = useContext(LoggedContext);

  useBodyClass("layout-logged");

  async function abreModalLogout(evt) {
    evt.preventDefault();
    let temDisponibilidadeAberta = await OrdemDeProducaoService.getDisponibilidadeAberta(context.maquina.id)
    if ( ! temDisponibilidadeAberta) {
      modalManager.mount(
        'modalLogout',
        <ModalLogout context={context} />
      );
      modalManager.show('modalLogout');
    } else {
      alert("Você não pode trocar de máquina enquanto está produzindo com esta máquina. Primeiro finalize a disponibilidade de máquina que está em aberto para depois usar outra máquina.");
    }
  }

  return (
    <>
      <Helmet>
        <title>Avance Empreendedor</title>
      </Helmet>

      <ToastContainer
        position="top-center"
        draggable={false}
        hideProgressBar={true}
        closeOnClick={false}
        autoClose={4000}
        transition={Slide}
      />

      <div id="modalContainer"></div>

      <div className="d-flex justify-content-between align-items-center ps-3 pe-3" id="header">
        <div className="menu1">
          <Link className="btn btn-sm" to={`/`}>
            <i className="icon icofont-navigation-menu"></i>
          </Link>
          {context.maquina.estamparia.nome}
        </div>
        <div className="menu2">
          {context.maquina.nome}
          <button
            type="button"
            className="btn btn-sm btn-logout"
            onClick={abreModalLogout}
          >
              <i className="icon icofont-ui-power"></i>
          </button>
        </div>
      </div>

      <main className="container-fluid" id="main">
        <div className="row">
          <div className="col" id="routeContent">
            {props.children}
          </div>
        </div>
      </main>

      <footer className="container-fluid" id="footer">
        <div className="faixa">Versão {process.env.REACT_APP_VERSION}</div>
      </footer>
    </>
  );
}