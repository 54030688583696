import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import auth from "../../services/auth";
import LayoutUnlogged from '../LayoutUnlogged';
import Logo from '../../assets/images/logo2.png';
import useBodyClass from '../../hooks/body-class';
import ListMaquinasLogadas from '../../components/OPEstamparia/ListMaquinasLogadas';
import './LoginPage.css';

export default function LoginPage() {

  const navigate = useNavigate();
  const [maquinaId, setMaquinaId] = useState();
  const [senha, setSenha] = useState();
  const [maquinasLogadas, setMaquinaLogadas] = useState(auth.listMaquinasLogadas() || []);

  useBodyClass("page-login");

  async function handleSubmit(e) {
    e.preventDefault();
    auth.logout();
    let response;
    try {
      response = await auth.login({
        maquinaId: maquinaId,
        senha: senha
      });
    } catch (error) {
      if (error.name === "AxiosError" && error.code === "ERR_NETWORK") {
        alert('Parece que você está desconectado da internet. Verifique a sua conexão e tente novamente.');
      } else if (error.response?.status === 0) {
        alert(error.message);
      } else if (error.response?.status < 500) {
        alert(error.response.data.description);
      } else if (error.response?.status >= 500) {
        alert("Ocorreu um erro no servidor e não foi possível fazer o login.");
      }
      return;
    }

    if (response.data.status !== 'ok') {
      alert('Usuário e senha não confere.');
      return;
    }

    if (auth.isAuthenticated()) {
      navigate('/');
    }
  }

  return (
    <LayoutUnlogged>
      <div className="logo-container">
        <img src={Logo} className="logo" />
      </div>
      {maquinasLogadas.length > 0 &&
        <div className="maquinaslogadas-container">
          <ListMaquinasLogadas
            esconderBotaoLogin={true}
            onConcludeLogout={maquinaId => {
              setMaquinaLogadas(auth.listMaquinasLogadas());
            }}
          />
        </div>
      }
      <div className="login-container">
        <h2 className="page-title">Login</h2>
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="form-group col-md-12 mb-3">
              <input
                type="text"
                className="form-control"
                onChange={e => {
                  setMaquinaId(e.target.value);
                }}
                placeholder="Usuário"
              />
            </div>
            <div className="form-group col-8">
              <input
                type="password"
                className="form-control"
                onChange={e => {
                  setSenha(e.target.value);
                }}
                placeholder="Senha"
              />
            </div>
            <div className="form-group col-4">
              <button type="submit" className="btn btn-primary">Enviar</button>
            </div>
          </div>
        </form>
      </div>
    </LayoutUnlogged>
  );
}
