import Funcionario from './Funcionario';

const Evento = {
    _status: 'aberto',
    _sync: 'false',
    ordemProducaoId: 0,
    maquinaId: 0,
    disponibilidadeUuid: 0,
    id: 0,
    tipo: "",
    inicio: "",
    fim: "",
    motivo: "",
    tempo: "",
    operador: JSON.parse(JSON.stringify(Funcionario))
}

export default Evento;