import React, { useState, useEffect } from 'react';
import './ModalEventoForm.css';
import auth from '../../services/auth';
import ListMaquinasLogadas from './ListMaquinasLogadas';

export default function ModalLogout(
  { onConfirm, onCancel, context, ...props }
) {

  const [maquinasLogadoas, setMaquinaLogadas] = useState([]);

  useEffect(() => {
    listMaquinasLogadas();
  }, []);

  function confirm(e) {
  }

  function cancel(e) {
    if (onCancel) {
      onCancel(e);
    }
  }

  function listMaquinasLogadas() {
    let keys = Object.keys(localStorage);
    let logadas = [];
    for (const key of keys) {
      if (/token-avance-maquina[0-9]*/.test(key)) {
        let maquinaId = key.match(/\d+/g)[0];
        let maquina = auth.getUsuario(auth.getToken(maquinaId));
        logadas.push(maquina);
      }
    }
    logadas = logadas.sort((a, b) => {
      if (a.nome > b.nome) return 1;
      if (a.nome < b.nome) return -1;
      return 0;
    });
    setMaquinaLogadas(logadas);
  }

  return (
    <div className="modal" id="modalLogout" tabIndex="-1">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Trocar de Máquina</h5>
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close">Cancelar</button>
          </div>
          <div className="modal-body">
            <div className="m-auto mt-4" style={{width: '500px'}}>
              <ListMaquinasLogadas
                context={context}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}