import { openDB } from 'idb';

const ordensproducaoStoreName = 'ordensproducao';
const disponibilidadesStoreName = 'disponibilidades_maquina';
const eventosStoreName = 'eventos';
const operadoresStoreName = 'operadores';

const database = {
    openDB: async () => {
        const db = await openDB('avance', 7, {
            upgrade(db, oldVersion, newVersion, transaction, event) {

                // Ordens de Produção
                if ( ! db.objectStoreNames.contains(ordensproducaoStoreName)) {
                    let ordensStore = db.createObjectStore(ordensproducaoStoreName, {
                        keyPath: 'id',
                        autoIncrement: false
                    });
                    ordensStore.createIndex("id", "id", { unique: true });
                    ordensStore.createIndex("status", "status", { unique: false });
                }

                // Disponibilidades de Maquina
                if ( ! db.objectStoreNames.contains(disponibilidadesStoreName)) {
                    let disponibilidadeStore = db.createObjectStore(disponibilidadesStoreName, {
                        keyPath: 'uuid',
                        autoIncrement: false
                    });
                    disponibilidadeStore.createIndex("uuid", "uuid", { unique: true });
                    disponibilidadeStore.createIndex("_sync", "_sync", { unique: false });
                    disponibilidadeStore.createIndex("_status", "_status", { unique: false });
                    disponibilidadeStore.createIndex("ordemProducaoId", "ordemProducaoId", { unique: false });
                }

                // Eventos
                let eventosStore;
                if (db.objectStoreNames.contains(eventosStoreName)) {
                    eventosStore = transaction.objectStore(eventosStoreName);
                } else {
                    eventosStore = db.createObjectStore(eventosStoreName, {
                        keyPath: '_id',
                        autoIncrement: true
                    });
                }
                if (eventosStore.indexNames.contains('ordemId')) {
                    eventosStore.deleteIndex("ordemId");
                }
                if ( ! eventosStore.indexNames.contains('_sync')) {
                    eventosStore.createIndex("_sync", "_sync", { unique: false });
                }
                if ( ! eventosStore.indexNames.contains('_status')) {
                    eventosStore.createIndex("_status", "_status", { unique: false });
                }
                if ( ! eventosStore.indexNames.contains('ordemProducaoId')) {
                    eventosStore.createIndex("ordemProducaoId", "ordemProducaoId", { unique: false });
                }
                if ( ! eventosStore.indexNames.contains('maquinaId')) {
                    eventosStore.createIndex("maquinaId", "maquinaId", { unique: false });
                }
                // ---

                // Operadores
                if ( ! db.objectStoreNames.contains(operadoresStoreName)) {
                    let operadoresStore = db.createObjectStore(operadoresStoreName, {
                        keyPath: 'id',
                        autoIncrement: false
                    });
                    operadoresStore.createIndex("id", "id", { unique: true });
                }
            }
        });

        return db;
    }
}

export {
    database as default,
    ordensproducaoStoreName,
    disponibilidadesStoreName,
    eventosStoreName,
    operadoresStoreName
}