import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import LayoutLogged from './LayoutLogged';

export default function RedirectPage() {

  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    navigate(searchParams.get('url'));
  }, []);

  return (
    <LayoutLogged></LayoutLogged>
  );
}