import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import useBodyClass from '../../hooks/body-class';
import './ListPage.css';
import LayoutLogged from '../LayoutLogged';
import api from '../../services/api';
import OrdemDeProducaoService from '../../services/OrdemDeProducaoService';

export default function ListPage() {

  const [isLoading, setIsLoading] = useState(false);
  const [ordens, setOrdens] = useState([]);

  useBodyClass("page-opestamparia");

  useEffect(() => {
    init();
  }, []);

  function init() {
    carregaOrdens();
  }

  function getSearchParams() {
    const limit = 150;
    const url = new URL(window.location.href);
    const page = parseInt(url.searchParams.get('page') || 1);
    url.searchParams.set('page', page);
    url.searchParams.set('status[]', 'aberta');
    url.searchParams.append('status[]', 'producao');
    url.searchParams.append('status[]', 'fechada');
    url.searchParams.set('limit', limit);
    return url;
  }

  function carregaOrdens() {
    carregaOrdensDoCache();
    carregaOrdensDoServidor();
  }

  async function carregaOrdensDoCache() {
    let ordensDoCache = await OrdemDeProducaoService.listaOrdensDoCache(1, 200);
    setOrdens(ordensDoCache);
  }

  async function carregaOrdensDoServidor() {
    let params = getSearchParams();
    api.get(`/op-estamparia` + params.search)
      .then(async (resp) => {
        await OrdemDeProducaoService.atualizaCache(resp.data.data);
        await carregaOrdensDoCache();
      })
      .catch(error => {
        if (error.response?.status < 500) {
          alert(error.response.data.description);
        } else if (error.response?.status > 500) {
          alert("Ocorreu um erro no servidor e não foi possível carregar as Ordens de Producação. Por favor, tente novamente.");
        } else {
          alert("Verifique a conexão com a internet.");
        }
      });
  }

  return (
    <LayoutLogged>
      <h2 className="page-title">Ordens de Produção</h2>
      <div className="ordens-container">
        <div className="mb-4">
          {isLoading &&
            <table className="table table-striped">
              <caption style={{ textAlign: 'center' }}>Odens de Produção</caption>
              <tbody>
                <tr style={{ padding: '20px', textAlign: 'center' }}>
                  <td>Carregando...</td>
                </tr>
              </tbody>
            </table>
          }
          {!isLoading && ordens.length == 0 &&
            <table className="table table-striped">
              <caption style={{ textAlign: 'center' }}>Odens de Produção</caption>
              <tbody>
                <tr style={{ padding: '20px', textAlign: 'center' }}>
                  <td>Não há Ordens de Produção cadastradas</td>
                </tr>
              </tbody>
            </table>
          }
          {!isLoading && ordens.length > 0 &&
            <table className="table table-striped table-sm">
              <thead className="table-dark">
                <tr>
                  <th scope="col">Ações</th>
                  <th scope="col">Cliente</th>
                  <th scope="col">O.F.</th>
                  <th scope="col">Marca</th>
                  <th scope="col">Ref.</th>
                  <th scope="col">Quantidade</th>
                  <th scope="col">ID</th>
                </tr>
              </thead>
              <tbody>
                {ordens.map((ordem, index) => (
                  <tr key={index}>
                    <td className="pb-2 pt-2">
                      <Link className="btn btn-secondary" to={`/op-estamparia/${ordem.id}`}>
                        PRODUZIR
                      </Link>
                    </td>
                    <td>{ordem.cliente ? ordem.cliente.nome : ''}</td>
                    <td>{ordem.ofCliente}</td>
                    <td>{ordem.marca}</td>
                    <td>{ordem.referencia}</td>
                    <td>{ordem.quantidade}</td>
                    <td>{ordem.id}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </div>
      </div>
    </LayoutLogged>
  );
}