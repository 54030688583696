import React, { useEffect, useState, createContext } from 'react';
import auth from "../services/auth";
import OperadorService from '../services/OperadorService';
import OrdemDeProducaoService from '../services/OrdemDeProducaoService';
import AppEvents from '../services/AppEvents';

export const LoggedContext = createContext();

const appEvents = new AppEvents();

let TIMER_SINCRONIZAR = null;

export default function LoggedProvider(props) {

  const [maquina, setMaquina] = useState(auth.getUsuario());
  const [operadores, setOperadores] = useState([]);

  useEffect(() => {
    if (TIMER_SINCRONIZAR === null) {
      sincronizar();
    }
  }, []);

  useEffect(() => {
    if (maquina.estamparia?.id > 0) {
      carregaOperadores();
    }
  }, [maquina]);

  function sincronizar() {
    TIMER_SINCRONIZAR = setTimeout(async() => {
      let resp = await OrdemDeProducaoService.sincronizarPrimeiroEventoEmAberto();
      appEvents.notify('sincronizacao-concluida');
      sincronizar();
    }, 15000);
    return TIMER_SINCRONIZAR;
  }

  function carregaOperadores() {
    carregaOperadoresDoCache();
    atualizaCacheDosOperadores();
  }

  async function carregaOperadoresDoCache() {
    let operadoresDoCache = await OperadorService.listaOperadoresDoCache();
    setOperadores(operadoresDoCache);
  }

  function atualizaCacheDosOperadores() {
    let req = OperadorService.atualizaCache();
    req.then((resp) => {
        carregaOperadoresDoCache();
      })
      .catch(error => {
        if (error.response?.status < 500) {
          alert(error.response.data.description);
        } else if (error.response?.status > 500) {
          alert("Ocorreu um erro no servidor e não foi possível carregar as Ordens de Producação. Por favor, tente novamente.");
        } else {
          alert("Verifique a conexão com a internet.");
        }
      });
  }

  return (
    <LoggedContext.Provider value={{
      appEvents,
      maquina, 
      operadores,
      atualizaCacheDosOperadores
    }}>
      {props.children}
    </LoggedContext.Provider>
  );
}