import React from 'react';
import useBodyClass from '../hooks/body-class';
import Footer from '../components/Footer/Footer';
import './LayoutUnlogged.css';

export default function LayoutUnlogged(props) {

  useBodyClass("layout-unlogged");

  return (
    <>
      <div id="conteudo">
        {props.children}
      </div>
      <Footer></Footer>
    </>
  );
}