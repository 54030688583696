import database from './database';
import api from './api';

const storeName = 'operadores';

const OperadorService = {
  atualizaCache: async () => {
    let promise = new Promise((resolve, reject) => {
      api.get('/funcionarios?ativo=sim')
        .then(async (resp) => {
          let db = await database.openDB();
          let operadores = resp.data;
          await db.clear(storeName);
          operadores.forEach(async(operador) => {
            await db.add(storeName, { ...operador });
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
    return promise;
  },
  listaOperadoresDoCache: async () => {
    let db = await database.openDB();
    let operadores = await db.getAll(storeName);
    return operadores.sort(function (a, b) {
      if (a.nome > b.nome) return 1;
      if (b.nome > a.nome) return -1;
      return 0;
    });
  },
}

export default OperadorService;