import axios from 'axios';
import auth from "./auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: { 'content-type': 'application/x-www-form-urlencoded' }
});

api.interceptors.request.use(async config => {
  const token = auth.getTokenOnline();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers.common['X-Tabletappversion'] = process.env.REACT_APP_VERSION;

  if (auth.isAuthenticated()) {
    const usuario = auth.getUsuario();
    config.headers.common['X-Estampariaid'] = usuario.id;
  }

  return config;
});

api.interceptors.response.use(function(response) {
    return response;
  }, function(error) {
    if (error.response?.status == 401) {
      auth.logout();
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;