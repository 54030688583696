import React from 'react';
import './Footer.css';

export default function Footer() {
  return (
    <>
    <footer className="container-fluid" id="footer">
      <div className="faixa">Versão {process.env.REACT_APP_VERSION}</div>
    </footer>
    </>
  );
}