import React, { useState, useEffect, useContext } from 'react';
import auth from '../../services/auth';
import './ListMaquinasLogadas.css';

export default function ListMaquinasLogadas({ 
  onConcludeLogout, onCancel, context, esconderBotaoLogin, ...props 
}) {

  const [maquinasLogadas, setMaquinaLogadas] = useState([]);
  const [maquinaParaLogout, setMaquinaParaLogout] = useState();

  if (esconderBotaoLogin === undefined) {
    esconderBotaoLogin = false;
  }

  useEffect(() => {
    listMaquinasLogadas();
  }, []);

  function confirm(e) {
  }

  function cancel(e) {
    if (onCancel) {
      onCancel(e);
    }
  }

  function listMaquinasLogadas() {
    let logadas = auth.listMaquinasLogadas();
    setMaquinaLogadas(logadas);
  }

  function trocaDeMaquina(e, maquina) {
    auth.setOnline(maquina.id);
    window.location = '/redirect?url=/';
  }

  function abreModalConfirmaLogout(maquina) {
    setMaquinaParaLogout({...maquina});
  }

  function cancelaLogout() {
    setMaquinaParaLogout();
  }

  function confirmaLogout(maquinaId) {
    auth.logout(maquinaId);
    if (context && context.maquina.id === maquinaId) {
      window.location = '/redirect?url=/';
      return;
    }
    listMaquinasLogadas();
    setMaquinaParaLogout();
    if(onConcludeLogout) onConcludeLogout(maquinaId);
  }

  return (
    <div className="listmaquinaslogadas-component">
      {maquinaParaLogout && 
        <div className="confirma-logout">
          <div className="mb-3">Quer remover o login desta máquina?</div>
          <button className="btn btn-primary me-5"
            onClick={e => confirmaLogout(maquinaParaLogout.id)}
          >
            Sim
          </button>
          <button className="btn btn-secondary"
            onClick={e => cancelaLogout()}
          >
            Cancelar
          </button>
        </div>
      }
      {!maquinaParaLogout && <>
        <h2 className="text-center mb-4" style={{fontSize: "1.5em"}}>Escolha a Máquina que deseja usar:</h2>
        {(maquinasLogadas||[]).map(maquina =>       
          <div key={maquina.id} className="d-flex flex-wrap text-center m-auto mb-3">
            <button
              className="btn btn-primary btn-loginfacil flex-grow-1 me-4"
              onClick={e => trocaDeMaquina(e, maquina)}
            >
              {maquina.nome}
            </button>
            <button
              className="btn btn-secondary flex-grow-0"
              onClick={e => abreModalConfirmaLogout(maquina)}
            >
              SAIR
            </button>
          </div>
        )}
        {esconderBotaoLogin === false &&
          <div className="d-flex flex-wrap text-center m-auto mb-3">
            <button
              className="btn btn-secondary btn-loginfacil flex-grow-1"
              onClick={e => window.location = '/login'}
            >
              Fazer login com outra máquina
            </button>
          </div>
        }
        </>
      }
    </div>
  );
}