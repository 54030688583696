import database, {
  ordensproducaoStoreName,
  disponibilidadesStoreName,
  eventosStoreName
} from './database';
import DisponibilidadeDeMaquina from '../models/DisponibilidadeDeMaquina';
import EventoModel from '../models/Evento';
import api from './api';
import { v1 as uuidv1 } from 'uuid';

const OrdemDeProducaoService = {

  removeTodasAsOrdensDoCache: async() => {
    let db = await database.openDB();
    await db.clear(ordensproducaoStoreName);
  },

  atualizaCache: async(ordens) => {
    OrdemDeProducaoService.removeTodasAsOrdensDoCache();
    (ordens || []).forEach(ordem => {
      OrdemDeProducaoService.adicionaOrdemNoCache(ordem);
    });
  },

  adicionaOrdemNoCache: async(ordem) => {
    let db = await database.openDB();
    await db.put(ordensproducaoStoreName, ordem);
  },

  quantidadeDeOrdensNoCache: async() => {
    let db = await database.openDB();
    return await db.countFromIndex(ordensproducaoStoreName, 'id');
  },

  listaOrdensDoCache: async(page, limit = 30) => {
    let db = await database.openDB();
    let cursor = await db.transaction(ordensproducaoStoreName).store.openCursor();
    let ordens = [];
    let offset = page - 1;
    for (let i = 1; i <= limit; i++) {
      if (i > offset) {
        ordens.push(cursor.value);
      }
      cursor = await cursor.continue();
      if (!cursor) break;
    }
    return ordens;
  },
  
  listaOrdensAbertasDoCache: async() => {
    const db = await database.openDB();
    return await db.getAllFromIndex(ordensproducaoStoreName, 'status', 'aberta');
  },

  getOrdemById: async(id) => {
    let db = await database.openDB();
    return await db.get(ordensproducaoStoreName, id);
  },

  fechaOrdensDoCache: async (ordens) => {
    let db = await database.openDB();
    (ordens || []).forEach(async (ordem) => {
      ordem.status = "finalizada";
      await db.put(ordensproducaoStoreName, ordem);
    });
  },

  getDisponibilidadeAberta: async (maquinaId) => {
    let db = await database.openDB();
    let disponibilidade = await db.getFromIndex(disponibilidadesStoreName, '_status', 'aberta');
    if (disponibilidade?.maquinaId === maquinaId) {
      return disponibilidade;
    }
    return null;
  },

  iniciaDisponibilidadeDeMaquina: async (ordemId, maquinaId, operador) => {
    let db = await database.openDB();
    let d = new Date();
    let disponibilidade = { ...DisponibilidadeDeMaquina };
    disponibilidade.uuid = uuidv1();
    disponibilidade._sync = 'false';
    disponibilidade._status = "aberta";
    disponibilidade.ordemProducaoId = ordemId;
    disponibilidade.maquinaId = maquinaId;
    disponibilidade.inicio = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "T" + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
    disponibilidade.operadorInicio = JSON.parse(JSON.stringify(operador));
    await db.add(disponibilidadesStoreName, disponibilidade);
    return disponibilidade;
  },

  terminaDisponibilidadeDeMaquina: async (disponibilidade, operador) => {
    let db = await database.openDB();
    let d = new Date();
    disponibilidade._sync = "false";
    disponibilidade._status = "fechada";
    disponibilidade.fim = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "T" + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
    disponibilidade.operadorFim = JSON.parse(JSON.stringify(operador));
    await db.put(disponibilidadesStoreName, disponibilidade);
    return disponibilidade;
  },

  podeProduzir: async (maquinaId, ordemId) => {
    let db = await database.openDB();
    let disponibilidadeAberta = await db.getFromIndex(disponibilidadesStoreName, '_status', 'aberta');
    if ( ! disponibilidadeAberta) {
      return true;
    }
    if (disponibilidadeAberta.maquinaId === maquinaId
      && disponibilidadeAberta.ordemProducaoId === ordemId
    ) {
      return true;
    }
    return false;
  },

  iniciaEvento: async(
    ordemId, maquinaId, disponibilidadeUuid, tipo, operador
  ) => {
    let db = await database.openDB();
    let d = new Date();
    let evento = JSON.parse(JSON.stringify(EventoModel));
    evento._status = 'aberto';
    evento._sync = 'false';
    evento.disponibilidadeUuid = disponibilidadeUuid;
    evento.ordemProducaoId = parseInt(ordemId);
    evento.maquinaId = parseInt(maquinaId);
    evento.tipo = tipo;
    evento.operador = JSON.parse(JSON.stringify(operador));
    evento.inicio = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "T" + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
    await db.add(eventosStoreName, evento);
  },

  terminaEvento: async(eventoId, motivo) => {
    let db = await database.openDB();
    let evento = await db.get(eventosStoreName, eventoId);
    let d = new Date();
    evento.fim = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "T" + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
    if (motivo) {
      evento.motivo = motivo;
    }
    evento._status = 'fechado';
    await db.put(eventosStoreName, evento);
  },

  getEventoAberto: async (ordemId) => {
    let db = await database.openDB();
    let evento = await db.getFromIndex(eventosStoreName, '_status', 'aberto');
    if (evento?.ordemProducaoId === ordemId) {
      return evento;
    }
    return null;
  },

  getEventosDesincronizados: async () => {
    const db = await database.openDB();
    let desincronizados = await db.getAllFromIndex(eventosStoreName, '_sync', "false");
    return desincronizados.filter(desinc => desinc._status === 'fechado');
  },

  getDisponibilidadesDesincronizadas: async () => {
    const db = await database.openDB();
    return await db.getAllFromIndex(disponibilidadesStoreName, '_sync', "false");
  },

  sincronizarPrimeiroEventoEmAberto: async() => {
    let disponibilidades = await OrdemDeProducaoService.getDisponibilidadesDesincronizadas();
    if (disponibilidades.length > 0) {
      let disponibilidade = disponibilidades[0];
      let resp;
      try {
        resp = await api.post(
          `/op-estamparia/${disponibilidade.ordemProducaoId}/maquinas/${disponibilidade.maquinaId}/disponibilidades`,
          disponibilidade
        );
        disponibilidade.id = resp.data.id;
        disponibilidade._sync = "true";
        let db = await database.openDB();
        await db.put(disponibilidadesStoreName, disponibilidade);
      } catch (error) {
        if (error.response?.status < 500) {
          console.log(error.response.data.description);
        } else if (error.response?.status > 500) {
          console.log("Ocorreu um erro no servidor e não foi possível salvar a disponibilidade de máquina.");
        } else {
          console.log('Verifique a conexão com a internet.');
        }
      }
      return;
    }
    
    let eventos = await OrdemDeProducaoService.getEventosDesincronizados();
    if (eventos.length > 0) {
      let evento = eventos[0];
      let resp;
      try {
        resp = await api.post(
          `/op-estamparia/${evento.ordemProducaoId}/maquinas/${evento.maquinaId}/eventos`,
          evento
        );
        evento.id = resp.data.id;
        evento._sync = "true";
        let db = await database.openDB();
        await db.put(eventosStoreName, evento);
      } catch (error) {
        if (error.response?.status < 500) {
          console.log(error.response.data.description);
        } else if (error.response?.status > 500) {
          console.log("Ocorreu um erro no servidor e não foi possível salvar o evento.");
        } else {
          console.log('Verifique a conexão com a internet.');
        }
      }
    }
  },

  logs: async(ordemProducaoId) => {
    const db = await database.openDB();
    const disponibilidades = await db.getAllFromIndex(disponibilidadesStoreName, 'ordemProducaoId', ordemProducaoId);
    const eventos = await db.getAllFromIndex(eventosStoreName, 'ordemProducaoId', ordemProducaoId);
    const eventosFechados = eventos.filter(evento => evento._status === 'fechado');
    const disponibilidadesEEventos = [...disponibilidades, ...eventosFechados];
    return disponibilidadesEEventos.sort((a, b) => {
      if (a.inicio > b.inicio) return 1;
      if (a.inicio < b.inicio) return -1;
      return 0;
    })
  }
}

export default OrdemDeProducaoService;