import React from 'react';
import { Routes, Route, Navigate  } from 'react-router-dom';
import auth from "./services/auth";
import LoggedContext from './contexts/LoggedContext';
import LoginPage from './pages/Login/LoginPage';
import OPEstampariaListPage from './pages/OPEstamparia/ListPage';
import OPEstampariaFormPage from './pages/OPEstamparia/FormPage';
import LogoutPage from './pages/Logout/LogoutPage';
import RedirectPage from './pages/RedirectPage';

function PrivateRoute({ component: Component, ...props }) {
  return auth.isAuthenticated() ? (
    <LoggedContext>
      <Component {...props} />
    </LoggedContext>
  ) : (
    <Navigate to="/login" replace />
  );
}

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/redirect" element={<PrivateRoute component={RedirectPage} />} />
      <Route path="/op-estamparia/:ordemId" element={<PrivateRoute component={OPEstampariaFormPage} />} />
      <Route path="/op-estamparia" element={<PrivateRoute component={OPEstampariaListPage} />} />
      <Route path="/" exact={true} element={<Navigate to="/op-estamparia" replace />} />
    </Routes>
  );
}